<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Keyboard, Navigation, Pagination } from 'swiper/modules';
import Image from '@ui/components/image/Image.vue';
import Modal from '@ui/components/modal/Modal.vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import IconButton from '@ui/components/icons/IconButton.vue';
import { ArrowLeft, ArrowRight } from '@ui/icons/filled';

const props = withDefaults(defineProps<{
  src?: string[],
}>(), {
  src: () => [],
})

const modules = [Keyboard, Pagination, Navigation, EffectFade];

const show = defineModel<boolean>('show')

const paginationClass = `image-viewer-pagination-${useId()}`;
</script>

<template lang="pug">
Modal(v-model:show="show" maxWidth="800px, 100dvh - 16px")
  template(#content)
    .flex.overflow-hidden.col-16.p-12-24-20
      .flex.row-12.overflow-hidden.h-100p
        IconButton.icon-button.prev-image(:name="ArrowLeft")
        Swiper.slider.h-100p(
          effect="fade"
          :slidesPerView="1"
          :loop="true"
          :keyboard="{enabled: true}"
          :navigation="{prevEl: '.prev-image', nextEl: '.next-image'}"
          :pagination="{clickable: true, el: '.' + paginationClass}"
          :modules="modules"
        )
          SwiperSlide(v-for="image of src")
            Image.slide-image(:src="image" :border="false" :contain="false")
        IconButton.icon-button.next-image(:name="ArrowRight")
      .pagination.text-center.h-18(:class="paginationClass")
</template>

<style lang="scss" scoped>
.pagination {
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bottom: -8px;
  --swiper-pagination-color: #3870FF;
  --swiper-pagination-bullet-inactive-color: #C3CACF;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.icon-button {
  --uno: br-14 p-14 s-48;
  background: #F8F8F9;
  color: #818D98 !important;
  align-self: center;
  display: none;

  @screen md {
    display: unset;
  }
}

.slider {
  aspect-ratio: 1;
  max-width: 100%;
  max-height: 100%;
}

.slide-image {
  width: 100%;
  height: 100%;
}
</style>